import axios from 'axios';

const baseEnv = process.env.VUE_APP_BASEENV;
const baseDomain = process.env.VUE_APP_BASEDOMAIN;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class Template {
  getTemplates(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/files/templates/${ownerId}`;

      axios.get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              if (resp.data.header.msg.startsWith('Nenhum arquivo encontrado')) {
                resolve(resp.data.data);
              } else {
                reject(resp.data.header.msg);
              }
            }
          },
          (err) => reject(err),
        );
    });
  }

  getTemplateByRef(fileRef, token) {
    return new Promise((resolve, reject) => {
      const tempFileType = 1;

      const url = `${baseUrl}/ConfigFiles/GetByTypeAndRef/${tempFileType}/${fileRef}`;
      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(url, config)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        );
    });
  }

  /* Template Config */
  createTemplate(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/configFiles`;
      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.post(url, payload, config)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        );
    });
  }

  deleteTemplate(fileRef, token) {
    return new Promise((resolve, reject) => {
      const tempFileType = 1;

      const url = `${baseUrl}/configFiles/${tempFileType}/${fileRef}`;
      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.delete(url, config)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        );
    });
  }

  updateTemplate(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/configFiles/updateConfigFileContent`;
      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.post(url, payload, config)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        );
    });
  }

  /* Template Files */
  saveTemplateFile(
    ownerId,
    file,
    templateName,
  ) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/files/templates/${ownerId}/${templateName}`;
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      const validFileName = String(templateName)
        .replace(/ /g, '-')
        .toLowerCase();

      const fileName = validFileName.includes('.html')
        ? validFileName
        : `${validFileName}.html`;

      let formData = new FormData();
      formData.append('File', file, fileName);

      axios.post(url, formData, config)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        );
    });
  }

  deleteTemplateFile(templateId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/files/templates/${templateId}`;

      axios.delete(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        )
    });
  }

  updateTemplateFile(
    ownerId,
    templateId,
    updatedName,
    updatedContent,
  ) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FileContent/posttemplate`;

      const htmlFileType = 1;

      const fileDef = {
        OwnerId: ownerId,
        Id: templateId,
        Nome: updatedName,
        Base64Content: updatedContent,
        fileType: htmlFileType
      };

      axios.post(url, fileDef)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => reject(err),
        );
    });
  }
}
