/*
  Before committing a new or modified block, make sure:
  - It is made up of components (makes the code reusable and less error-prone);
  - The variables defined in the "template" string match the properties in the "props" object;
  - It doesnt use any of the following HTML tags in the template: <br>, <form>, <input>, <iframe>, <link>, <video>, <audio>;
  - Its using the <table> element along with the spacing styles to achieve the desired layout;
*/
const getBlocks = (tokens) => {
  // Placeholders and Theme
  const currentYear = new Date().getFullYear();
  const lorem18 = 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse voluptatum aspernatur earum praesentium deserunt delectus laborum sequi explicabo.';
  const {
    btnColor,
    imageSlotUrl,
    logo,
    logoWidth,
    ownerCopyright,
  } = tokens;

  // Table (use on <table>)
  const tableProps = 'align="center" cellpadding="0" cellspacing="0"';
  const tableProps100 = 'align="center" cellpadding="0" cellspacing="0" width="100%"'; // generally applied to paragraphs and titles

  // Table Data (use on <td>)
  const alignCenter = 'align="center"';
  const spacing = 'align="center" style="padding: 16px 16px 8px;"';
  const spacingSm = 'align="center" style="padding: 12px 6px;"';
  const spacingB = 'align="center" style="padding: 0px 0px 20px;"';
  const spacingBs = 'align="center" style="padding: 0px 0px 12px;"';
  const spacingX = 'align="center" style="padding: 0px 12px;"';
  const spacingY = 'align="center" style="padding: 12px 0px;"';

  // Styles
  const btnStyles = 'padding: 12px; border: 0px; border-radius: 9px; box-sizing: border-box; font-weight: bold; text-decoration: none; outline: none; display: inline-block; text-align: center';
  const dividerStyles = 'display: inline-block; width: 100%; height: 1px';
  const dividerStylesVertical = 'display: inline-block; width: 1px; height: 25px; margin-left: 12px; margin-right: 12px';
  const h1Styles = 'margin: 0px';
  const linkStyles = 'text-decoration: none';
  const pStyles = 'margin: 0px';

  // Components
  const dividerComponent = (vertical, index) => {
    if (vertical) {
      if (index) {
        return `<span style="${dividerStylesVertical}; background-color: {{divider_${index}.backgroundColor}};"></span>`;
      } else {
        return `<span style="${dividerStylesVertical}; background-color: {{divider.backgroundColor}};"></span>`;
      }
    } else {
      return `<span style="${dividerStyles}; background-color: {{divider.backgroundColor}};"></span>`;
    }
  };

  const h1Component = () => {
    return `<h1 style="${h1Styles}; color: {{title.color}}; font-family: {{title.fontFamily}}; font-size: {{title.fontSize}}; line-height: {{title.lineHeight}}; text-align: {{title.textAlign}};">{{title.text}}</h1>`;
  };

  const paragraphComponent = () => {
    return `<p style="${pStyles}; color: {{paragraph.color}}; font-family: {{paragraph.fontFamily}}; font-size: {{paragraph.fontSize}}; line-height: {{paragraph.lineHeight}}; text-align: {{paragraph.textAlign}};">{{paragraph.text}}</p>`;
  };

  const linkComponent = (index) => {
    if (index) {
      return `<a style="${linkStyles}; color: {{link_${index}.color}}; font-family: {{link_${index}.fontFamily}}; font-size: {{link_${index}.fontSize}}; line-height: {{link_${index}.lineHeight}};" data-href="{{link_${index}.href}}" target="_blank">{{link_${index}.text}}</a>`;
    } else {
      return `<a style="${linkStyles}; color: {{link.color}}; font-family: {{link.fontFamily}}; font-size: {{link.fontSize}}; line-height: {{link.lineHeight}};" data-href="{{link.href}}" target="_blank">{{link.text}}</a>`;
    }
  };

  const logoComponent = (index) => {
    if (index) {
      return `<a data-href="{{logo_${index}.href}}" target="_blank" style="display: inline-block;"><img src="{{logo_${index}.src}}" style="width: {{logo_${index}.width}};" width="{{logo_${index}.width}}"></a>`;
    } else {
      return `<table ${tableProps}><tbody><tr><td ${spacingY}><a data-href="{{logo.href}}" target="_blank" style="display: inline-block;"><img src="{{logo.src}}" style="max-width: 100%; width: {{logo.width}};" width="{{logo.width}}"></a></td></tr></tbody></table>`;
    }
  };

  const buttonComponent = () => {
    return `<a data-href="{{button.href}}" rel="noopener" target="_blank" style="${btnStyles}; width: {{button.width}}; background-color: {{button.backgroundColor}}; color: {{button.color}}; font-family: {{button.fontFamily}}; font-size: {{button.fontSize}}; line-height: {{button.lineHeight}};"><!--[if mso]><i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i><![endif]--><span style="mso-text-raise: 15pt;">{{button.text}}</span><!--[if mso]><i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i><![endif]--></a>`;
  };

  const imageComponent = () => {
    return `<table ${tableProps100}><tbody><tr><td ${spacingB}><a data-href="{{img.href}}" target="_blank" style="display: inline-block;"><img src="{{img.src}}" style="max-width: 100%; width: {{img.width}};" width="{{img.width}}"></a></td></tr></tbody></table>`;
  };

  const imageNoMarginComponent = () => {
    return `<table ${tableProps100}><tbody><tr><td align="center"><a data-href="{{img.href}}" target="_blank" style="display: inline-block;"><img src="{{img.src}}" style="max-width: 100%; width: {{img.width}};" width="{{img.width}}"></a></td></tr></tbody></table>`;
  };

  return [
    {
      id: 'Vazio',
      type: 'block',
      template: '<div></div>',
      cellPadding: '12px',
      colMinHeight: '140px',
      hidden: true,
    },
    {
      id: 'Cabeçalho',
      type: 'block',
      tags: ['Cabeçalho'],
      illustration: 'header.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingB}><p style="${pStyles}; color: {{paragraph.color}}; font-family: {{paragraph.fontFamily}}; font-size: {{paragraph.fontSize}}; line-height: {{paragraph.lineHeight}};">{{paragraph.text}}${linkComponent()}</p></td></tr></tbody></table>${dividerComponent()}`,
      cellPadding: '12px',
      colMinHeight: '60px',
      props: {
        paragraph: {
          text: '[Insira aqui uma breve descrição do seu e-mail]. ',
          color: '#808080',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          textAlign: 'center',
        },
        link: {
          text: 'Visualizar esta mensagem on-line',
          color: '#0f90c2',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          href: '{{webversion}}',
        },
        divider: {
          backgroundColor: '#cfcfcf',
        },
      },
    },
    {
      id: 'Logo',
      type: 'block',
      tags: ['Logo'],
      illustration: 'logo.png',
      template: `${logoComponent()}`,
      cellPadding: '12px',
      colMinHeight: '124px',
      props: {
        logo: {
          href: '',
          src: logo,
          width: `${logoWidth}px`,
        },
      },
    },
    {
      id: 'Logo + Mídia Social',
      type: 'block',
      tags: ['Logo', 'Mídia Social'],
      illustration: 'logo_socialmedia.png',
      template: `${logoComponent()}<table ${tableProps}><tbody><tr><td ${spacingSm}>${logoComponent(1)}</td><td ${spacingSm}>${logoComponent(2)}</td><td ${spacingSm}>${logoComponent(3)}</td><td ${spacingSm}>${logoComponent(4)}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '74px',
      props: {
        logo: {
          href: '',
          src: logo,
          width: `${logoWidth}px`,
        },
        logo_1: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-facebook.png',
          width: '30px',
        },
        logo_2: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-twitter.png',
          width: '30px',
        },
        logo_3: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-instagram.png',
          width: '30px',
        },
        logo_4: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-tiktok.png',
          width: '30px',
        },
      },
    },
    {
      id: 'Título + Logo',
      type: 'block',
      tags: ['Título', 'Logo'],
      illustration: 'title_logo.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingB}>${h1Component()}</td></tr></tbody></table>${logoComponent()}`,
      cellPadding: '12px',
      colMinHeight: '180px',
      props: {
        title: {
          text: '[Insira seu título aqui]',
          color: '#111111',
          fontFamily: 'Arial, sans-serif',
          fontSize: '30px',
          lineHeight: '42px',
          textAlign: 'center',
        },
        logo: {
          href: '',
          src: logo,
          width: `${logoWidth}px`,
        },
      },
    },
    {
      id: 'Título + Parágrafo',
      type: 'block',
      tags: ['Título', 'Parágrafo'],
      illustration: 'title_paragraph.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingB}>${h1Component()}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacingB}>${paragraphComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '148px',
      props: {
        title: {
          text: '[Insira seu título aqui]',
          color: '#111111',
          fontFamily: 'Arial, sans-serif',
          fontSize: '30px',
          lineHeight: '42px',
          textAlign: 'center',
        },
        paragraph: {
          text: lorem18,
          color: '#222222',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '19.6px',
          textAlign: 'center',
        },
      },
    },
    {
      id: 'Parágrafo',
      type: 'block',
      tags: ['Parágrafo'],
      illustration: 'paragraph.png',
      template: `${paragraphComponent()}`,
      cellPadding: '12px',
      colMinHeight: '74px',
      props: {
        paragraph: {
          text: lorem18,
          color: '#222222',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '19.6px',
          textAlign: 'center',
        },
      },
    },
    {
      id: 'Parágrafo + Botão',
      type: 'block',
      tags: ['Parágrafo', 'Botão'],
      illustration: 'paragraph_button.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingB}>${paragraphComponent()}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacingBs}>${buttonComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '154px',
      props: {
        paragraph: {
          text: lorem18,
          color: '#222222',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '19.6px',
          textAlign: 'center',
        },
        button: {
          text: 'Botão',
          href: '',
          backgroundColor: btnColor,
          color: '#FFFFFF',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '160px',
        },
      },
    },
    {
      id: 'Título + Parágrafo + Botão',
      type: 'block',
      tags: ['Título', 'Parágrafo', 'Botão'],
      illustration: 'title_paragraph_button.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingB}>${h1Component()}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacingB}>${paragraphComponent()}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacingBs}>${buttonComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '210px',
      props: {
        title: {
          text: '[Insira seu título aqui]',
          color: '#111111',
          fontFamily: 'Arial, sans-serif',
          fontSize: '30px',
          lineHeight: '42px',
          textAlign: 'center',
        },
        paragraph: {
          text: lorem18,
          color: '#222222',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '19.6px',
          textAlign: 'center',
        },
        button: {
          text: 'Botão',
          href: '',
          backgroundColor: btnColor,
          color: '#FFFFFF',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '160px',
        },
      },
    },
    {
      id: 'Botão',
      type: 'block',
      tags: ['Botão'],
      illustration: 'button.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingY}>${buttonComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '60px',
      props: {
        button: {
          text: 'Botão',
          href: '',
          backgroundColor: btnColor,
          color: '#FFFFFF',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '160px',
        },
      },
    },
    {
      id: 'Título + Parágrafo + Imagem + Botão',
      type: 'block',
      tags: ['Título', 'Parágrafo', 'Imagem', 'Botão'],
      illustration: 'title_paragraph_image_button.png',
      template: `<table ${tableProps100}><tbody><tr><td ${spacingB}>${h1Component()}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacingB}>${paragraphComponent()}</td></tr></tbody></table>${imageComponent()}<table ${tableProps100}><tbody><tr><td ${spacingBs}>${buttonComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '60px',
      props: {
        title: {
          text: '[Insira seu título aqui]',
          color: '#111111',
          fontFamily: 'Arial, sans-serif',
          fontSize: '30px',
          lineHeight: '42px',
          textAlign: 'center',
        },
        paragraph: {
          text: lorem18,
          color: '#222222',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '19.6px',
          textAlign: 'center',
        },
        img: {
          href: '',
          src: imageSlotUrl,
          width: '200px',
        },
        button: {
          text: 'Botão',
          href: '',
          backgroundColor: btnColor,
          color: '#FFFFFF',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '160px',
        },
      },
    },
    {
      id: 'Imagem',
      type: 'block',
      tags: ['Imagem'],
      illustration: 'image.png',
      template: `${imageNoMarginComponent()}`,
      cellPadding: '0px',
      colMinHeight: '50px',
      props: {
        img: {
          href: '',
          src: imageSlotUrl,
          width: '200px',
        },
      },
    },
    {
      id: 'Imagem + Botão',
      type: 'block',
      tags: ['Imagem', 'Botão'],
      illustration: 'image_button.png',
      template: `${imageComponent()}<table ${tableProps100}><tbody><tr><td ${spacingBs}>${buttonComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '261px',
      props: {
        img: {
          href: '',
          src: imageSlotUrl,
          width: '200px',
        },
        button: {
          text: 'Botão',
          href: '',
          backgroundColor: btnColor,
          color: '#FFFFFF',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '160px',
        },
      },
    },
    {
      id: 'Imagem + Parágrafo + Botão',
      type: 'block',
      tags: ['Imagem', 'Parágrafo', 'Botão'],
      illustration: 'image_paragraph_button.png',
      template: `${imageComponent()}<table ${tableProps100}><tbody><tr><td ${spacingB}>${paragraphComponent()}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacingBs}>${buttonComponent()}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '327px',
      props: {
        img: {
          href: '',
          src: imageSlotUrl,
          width: '200px',
        },
        paragraph: {
          text: lorem18,
          color: '#222222',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '19.6px',
          textAlign: 'center',
        },
        button: {
          text: 'Botão',
          href: '',
          backgroundColor: btnColor,
          color: '#FFFFFF',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '22.4px',
          width: '160px',
        },
      },
    },
    {
      id: 'Menu',
      type: 'block',
      tags: ['Menu'],
      illustration: 'menu.png',
      template: `<table ${tableProps}><tbody><tr><td ${spacingY}>${linkComponent(1)}</td><td ${spacingX}>${dividerComponent(true, 1)}</td><td ${spacingY}>${linkComponent(2)}</td><td ${spacingX}>${dividerComponent(true, 2)}</td><td ${spacingY}>${linkComponent(3)}</td><td ${spacingX}>${dividerComponent(true, 3)}</td><td ${spacingY}>${linkComponent(4)}</td><td ${spacingX}>${dividerComponent(true, 4)}</td><td ${spacingY}>${linkComponent(5)}</td><td ${spacingX}>${dividerComponent(true, 5)}</td><td ${spacingY}>${linkComponent(6)}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '50px',
      props: {
        link_1: {
          text: 'Link 1',
          color: '#454545',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '22.4px',
          href: '',
        },
        divider_1: {
          backgroundColor: '#cfcfcf',
        },
        link_2: {
          text: 'Link 2',
          color: '#454545',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '22.4px',
          href: '',
        },
        divider_2: {
          backgroundColor: '#cfcfcf',
        },
        link_3: {
          text: 'Link 3',
          color: '#454545',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '22.4px',
          href: '',
        },
        divider_3: {
          backgroundColor: '#cfcfcf',
        },
        link_4: {
          text: 'Link 4',
          color: '#454545',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '22.4px',
          href: '',
        },
        divider_4: {
          backgroundColor: '#cfcfcf',
        },
        link_5: {
          text: 'Link 5',
          color: '#454545',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '22.4px',
          href: '',
        },
        divider_5: {
          backgroundColor: '#cfcfcf',
        },
        link_6: {
          text: 'Link 6',
          color: '#454545',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '22.4px',
          href: '',
        },
      },
    },
    {
      id: 'Mídia Social',
      type: 'block',
      tags: ['Mídia Social'],
      illustration: 'socialmedia.png',
      template: `<table ${tableProps}><tbody><tr><td ${spacing}>${logoComponent(1)}</td><td ${spacing}>${logoComponent(2)}</td><td ${spacing}>${logoComponent(3)}</td><td ${spacing}>${logoComponent(4)}</td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '74px',
      props: {
        logo_1: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-facebook.png',
          width: '30px',
        },
        logo_2: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-twitter.png',
          width: '30px',
        },
        logo_3: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-instagram.png',
          width: '30px',
        },
        logo_4: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-tiktok.png',
          width: '30px',
        },
      },
    },
    {
      id: 'Rodapé + Mídia Social',
      type: 'block',
      tags: ['Rodapé', 'Mídia Social'],
      illustration: 'footer_socialmedia.png',
      template: `${dividerComponent()}<table ${tableProps100}><tbody><tr><td align="center" style="padding: 20px 0px 12px;"><p style="${pStyles}; color: {{paragraph.color}}; font-family: {{paragraph.fontFamily}}; font-size: {{paragraph.fontSize}}; line-height: {{paragraph.lineHeight}};">{{paragraph.text}}</p></td></tr></tbody></table><table ${tableProps}><tbody><tr><td ${spacing}>${logoComponent(1)}</td><td ${spacing}>${logoComponent(2)}</td><td ${spacing}>${logoComponent(3)}</td><td ${spacing}>${logoComponent(4)}</td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${spacing}><p style="${pStyles}; color: {{paragraph2.color}}; font-family: {{paragraph2.fontFamily}}; font-size: {{paragraph2.fontSize}}; line-height: {{paragraph2.lineHeight}};">{{paragraph2.text}}${linkComponent()}</p></td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '184px',
      props: {
        divider: {
          backgroundColor: '#cfcfcf',
        },
        paragraph: {
          text: `© ${ownerCopyright}, ${currentYear}. Todos os direitos reservados.`,
          color: '#808080',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          textAlign: 'center',
        },
        paragraph2: {
          text: `Você recebeu este e-mail como cliente ou assinante de ${ownerCopyright}. `,
          color: '#808080',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          textAlign: 'center',
        },
        link: {
          text: 'Cancelar inscrição',
          color: '#0f90c2',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          href: '{{unsubscribe_url}}',
        },
        logo_1: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-facebook.png',
          width: '30px',
        },
        logo_2: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-twitter.png',
          width: '30px',
        },
        logo_3: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-instagram.png',
          width: '30px',
        },
        logo_4: {
          href: '',
          src: 'https://config.fanbase.com.br/images/template-builder/network-tiktok.png',
          width: '30px',
        },
      },
    },
    {
      id: 'Rodapé',
      type: 'block',
      tags: ['Rodapé'],
      illustration: 'footer.png',
      template: `${dividerComponent()}<table ${tableProps100}><tbody><tr><td align="center" style="padding: 20px 0px 12px;"><p style="${pStyles}; color: {{paragraph.color}}; font-family: {{paragraph.fontFamily}}; font-size: {{paragraph.fontSize}}; line-height: {{paragraph.lineHeight}}; text-align: {{paragraph.textAlign}};">{{paragraph.text}}</p></td></tr></tbody></table><table ${tableProps100}><tbody><tr><td ${alignCenter}><p style="${pStyles}; color: {{paragraph2.color}}; font-family: {{paragraph2.fontFamily}}; font-size: {{paragraph2.fontSize}}; line-height: {{paragraph2.lineHeight}}; text-align: {{paragraph.textAlign}};">{{paragraph2.text}}${linkComponent()}</p></td></tr></tbody></table>`,
      cellPadding: '12px',
      colMinHeight: '130px',
      props: {
        divider: {
          backgroundColor: '#cfcfcf',
        },
        paragraph: {
          text: `© ${ownerCopyright}, ${currentYear}. Todos os direitos reservados.`,
          color: '#808080',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          textAlign: 'center',
        },
        paragraph2: {
          text: `Você recebeu este e-mail como cliente ou assinante de ${ownerCopyright}. `,
          color: '#808080',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          textAlign: 'center',
        },
        link: {
          text: 'Cancelar inscrição',
          color: '#0f90c2',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
          lineHeight: '16.8px',
          href: '{{unsubscribe_url}}',
        },
      },
    },
  ];
};

export default getBlocks;
