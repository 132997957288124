const translateBlockToHtml = (block, translations) => {
  const tokenPattern = /{{(.*?)}}/g;

  // Replace tokens in the HTML template using the translations object
  const translated = block.replace(tokenPattern, (match, token) => {
    const keys = token.split('.'); // Split the token by '.' to access nested translations
    let value = translations;

    // Traverse the translations object to get the correct translation
    for (const key of keys) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        value = value[key];
      } else {
        value = match; // If translation is not found, keep the original token
        break;
      }
    }

    return value;
  });

  return translated;
};

export default translateBlockToHtml;
