const appendDataLocator = (html, locator) => {
  // Define the pattern to match the HTML element
  const pattern = /<([a-zA-Z0-9]+)(\s[^>]*)?>/g;

  // Find the first match of the HTML element
  const match = pattern.exec(html);

  if (match) {
    // Get the matched HTML element
    const element = match[0];
    const modifiedElement = `${element.replace('>', ` data-locator="${locator}">`)}`;
    const modifiedHtml = html.replace(element, modifiedElement);

    return modifiedHtml;
  }

  // Return the original HTML if no match found
  return html;
};

export default appendDataLocator;
